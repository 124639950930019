<template>
  <div class="page-thanks page">
    <BreadcrumbsComponent title="Спасибо за покупку" />
    <div class="container-padding">
      <div class="page__inner page-thanks__inner">
        <div class="page-thanks__container">
          <div class="page-thanks__content">
            <span class="page-thanks__icon">
              <IconComponent name="like-shapes" />
            </span>
            <span class="page-thanks__title">
              Ваш заказ №{{ params.id }} от {{ params.date | formatDateTime }} успешно создан.
            </span>
            <span class="page-thanks__message">
              Вы можете следить за выполнением своего заказа в
              <a href="/user/orders" class=""> Персональном разделе сайта. </a>
              Обратите внимание, что для входа в этот раздел вам необходимо будет авторизоваться через номер
              телефона или ввести логин и пароль пользователя сайта.
            </span>
          </div>
          <div class="page-thanks__footer">
            <span class="page-thanks__footer-title"> Оплата заказа </span>
            <span class="page-thanks__footer-subtitle"> {{ orderType }}</span>
          </div>
        </div>
        <div class="page-thanks__buttons">
          <router-link :to="{ name: 'home' }" class="btn btn--md btn--main">Вернуться на главную</router-link>
          <router-link :to="{ name: 'user', params: { link: 'orders' } }" class="btn btn--md btn--main"
            >Мои заказы</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import IconComponent from "components/IconComponent.vue";
import NOT_FOUND_PAGE from "@/graphql/pages/NotFoundPage.graphql";

function parseParams(query) {
  Object.keys(query).forEach((key) => {
    try {
      query[key] = JSON.parse(query[key]);
    } catch (e) {
      return null;
    }
  });
  return query;
}
export default {
  components: { IconComponent, BreadcrumbsComponent },
  async asyncData({ res, apollo, store }) {
    await apollo.defaultClient
      .query({
        query: NOT_FOUND_PAGE,
      })
      .then(({ data }) => {
        store.state.page_404.products = data.products_paginate?.data;
        // дефолтные данные
        store.state.global.header_categories = data.header_categories;
        store.state.global.footer_categories = data.footer_categories;
        store.state.global.categories = data.categories;
        store.state.global.brands = data.brands;
        store.state.global.info = data.info || {};
        store.state.global.pages = data.pages;
        store.state.global.branches = data.filials;
      })
      .catch(() => {});
    if (res) {
      res.status(404);
    }
  },
  data() {
    return {
      params: {},
    };
  },
  created() {
    this.params = parseParams(this.$route.query);
  },
  computed: {
    products() {
      return this.$store.state.page_404.products;
    },
    types() {
      return this.$store.state._types;
    },
    orderType() {
      if (this.params.type) {
        switch (this.params.type) {
          case this.types.ONLINE_CARD_PAY: {
            return "Оплата онлайн";
          }
          case this.types.OFFLINE_CARD_PAY: {
            return "Оплата картой при получении";
          }
          case this.types.OFFLINE_CASH_PAY: {
            return "Оплата наличными при получении";
          }
        }
      }
      return undefined;
    },
  },
  metaInfo: {
    title: "Спасибо за покупку!",
  },
};
</script>

<style lang="stylus">
.page-thanks {
  padding-bottom 172px
  +below(768px) {
    padding-bottom 30px
  }

  &__inner {
    padding-top 46px
    align-items center
    +below(540px) {
      padding-top 0
    }
  }

  &__buttons {
    display flex
    gap 16px
    width 100%
    max-width 557px

    & .btn {
      width 100%
    }
  }

  &__container {
    margin 0 auto
    max-width 557px
    border-radius var(--big-radius)
    background var(--white)
    filter: drop-shadow(0px 308px 123px rgba(25, 41, 57, 0.01)) drop-shadow(0px 173px 104px rgba(25, 41, 57, 0.05)) drop-shadow(0px 77px 77px rgba(25, 41, 57, 0.09)) drop-shadow(0px 19px 42px rgba(25, 41, 57, 0.1)) drop-shadow(0px 0px 0px rgba(25, 41, 57, 0.1));
  }

  &__content {
    padding 30px
    display flex
    flex-direction column
    align-items center
    text-align center
    gap: 30px
    +below(440px) {
      padding 15px
    }
  }

  &__icon {
    width 150px
    height: 150px
    flex-shrink 0
    display flex
    justify-content center
    align-items center
    border-radius 50%
    border: 1px solid var(--gray)

    .icon {
      width: 60px
      height: 60px
    }
  }

  &__title {
    font-weight: 700;
    font-size: 1.375em
    line-height: 28px;
    +below(540px) {
      font-size 1.25em
    }
  }

  &__subtitle {
    font-weight: 700;
    font-size: 1.125em
    line-height: 28px;
  }

  &__message {
    font-size 1.125em
    +below(540px) {
      font-size 1em
    }
  }

  //& .btn {
  //}

  &__footer {
    padding 30px
    display flex
    flex-direction column
    align-items center
    text-align center
    gap: 5px
    border-top 1px solid var(--gray)
    +below(440px) {
      padding 15px
    }


    &-title {
      font-weight: 700;
      font-size: 1.375em
      line-height: 28px;
      +below(540px) {
        font-size 1.25em
      }
    }
  }
}
</style>
